<script setup lang = "ts">
import { defineProps } from "vue";

import type { DataItemModel } from "o365-dataobject"

import { getFileIconClass } from 'arena.vue.components.common.js';
import { MListItem  } from 'o365-mobile';

const props = defineProps<{
    row: DataItemModel
}>();

</script>

<template>
    <MListItem :row="row">
        <div class="d-flex justify-content-between">
            <div class="d-flex gap-2">
                <!-- <FileIcon class = "align-self-center" :extencion="row.Extension" size="1.5em" /> -->
                <i class="align-self-center" :class="getFileIconClass(row.Extension)"
                    :title="row.Extension" style="font-size: 1.5em;"></i>
                <div class="align-self-center">
                    <div class="fw-medium">
                        {{ row.Name ? row.Name : '-' }}
                    </div>

                    <div class="text-muted" style="font-size: 0.875em;">
                        {{ row.DocumentTypeAndTitle }}
                    </div>
                </div>
            </div>
            <i class="bi bi-three-dots px-2 align-self-center" style="font-size: 1.125rem;"></i>
        </div>
    </MListItem>
</template>
